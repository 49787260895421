import React, { Fragment, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import AnonymousLayout from '../../components/AnonymousLayout';
import config from '../../config';
import { authenticationService } from '../../_services/authentication.service';

const EmailVerification = () => {
    let params = useParams();

    const [sending, setSending] = useState(false);

    useEffect(() => {
        document.title = `Email Verification | ${config.projectName}`;
    }, []);
    const onSendEmail = () => {
        if (!sending) {
            setSending(true);
            authenticationService.sendVerificationEmail(params.email)
                .then(g => {
                    console.log(g);
                    toast.success(g.message);
                    setSending(false);
                })
                .catch(err => {
                    toast.error(err.response.data.message);
                    setSending(false);
                })
        }
    }
    return (
        <AnonymousLayout>
            <h3>{params.type === "register-success" ? "Almost Done!" : "Error"}</h3>
            <div className="mt-2">
                {params.type !== "register-success" && <h5>Your Email is not verified yet!</h5>}
                <span>We've emailed a verification link to:</span>
                <p className="primary-text">{params.email}</p>
                <span className="light-gray">Click the link in email to finish creating your account.</span>
                <br />
                <span className="light-gray">Didn't receive a link?</span>
                <br />
                <br />
                <button className="btn btn-primary" onClick={onSendEmail} disabled={sending}>{sending ? "Sending.." : "Send Email"}</button>
                <p className='mt-4 form-navigation-text'>Confirmed? <Link to="/login" className="primary-text">Back to Login</Link></p>
            </div>
        </AnonymousLayout >
    );
};

export default EmailVerification;