import {useEffect, useState} from 'react';
import useLoonFinance from './useLoonFinance';
import {TokenStat} from '../bomb-finance/types';
import useRefresh from './useRefresh';

const useLoonStats = () => {
  const [stat, setStat] = useState<TokenStat>();
  const {fastRefresh} = useRefresh();
  const bombFinance = useLoonFinance();

  useEffect(() => {
    async function fetchLoonPrice() {
      try {
        setStat(await bombFinance.getLoonStat());
      } catch (err) {
        console.error(err);
      }
    }
    fetchLoonPrice();
  }, [setStat, bombFinance, fastRefresh]);

  return stat;
};

export default useLoonStats;
