import React, {createContext, useEffect, useState} from 'react';
import {useWallet} from 'use-wallet';
import LoonFinance from '../../bomb-finance';
import config from '../../config';

export interface LoonFinanceContext {
  bombFinance?: LoonFinance;
}

export const Context = createContext<LoonFinanceContext>({bombFinance: null});

export const LoonFinanceProvider: React.FC = ({children}) => {
  const {ethereum, account} = useWallet();
  const [bombFinance, setLoonFinance] = useState<LoonFinance>();

  useEffect(() => {
    if (!bombFinance) {
      const bomb = new LoonFinance(config);
      if (account) {
        // wallet was unlocked at initialization
        bomb.unlockWallet(ethereum, account);
      }
      setLoonFinance(bomb);
    } else if (account) {
      bombFinance.unlockWallet(ethereum, account);
    }
  }, [account, ethereum, bombFinance]);

  return <Context.Provider value={{bombFinance}}>{children}</Context.Provider>;
};
