import { Alert } from '@material-ui/lab';
import { validate } from 'numeral';
import React, { useEffect, useState } from 'react';
import { Switch } from 'react-router-dom';
import { toast } from 'react-toastify';
import FieldsGenerator from '../components/FieldsGenerator';
import Page from '../components/Page';
import fillInObject from '../_helpers/fillInObject';
import generateValidation from '../_helpers/generateValidation';
import getImageURL from '../_helpers/imageHelper';
import { onChangeHandler } from '../_helpers/imageUploadValidator';
import { authenticationService } from '../_services/authentication.service';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import './style.css';
import currencyDisplayHelper from '../_helpers/currencyDisplayHelper';
import { apiService } from '../_services/api.service';
import socketUpdater from '../_helpers/socketUpdater';
import BuyFromCard from '../views/Purchase/BuyFromCard';
import config from '../_config';

const Profile = ({ history, match }) => {
    const [user, setUser] = useState(null);
    const [selectedProfileImage, setProfileImage] = useState(null);
    const [bluePrint, setBluePrint] = useState({
        'profileImage': {
            type: String,
            label: "Profile Image",
            placeholder: "Profile Image",
            icon: "ti-user",
            iconColor: "color-primary",
            dataType: "String",
            default: "",
            required: true,
            pattern: "[a-zA-Z0-9]{4,15}",
            hidden: true
        },
        'name': {
            type: String,
            label: "Name",
            placeholder: "Enter Name",
            icon: "ti-user",
            iconColor: "color-primary",
            dataType: "String",
            default: "",
            required: true,
            pattern: "[a-zA-Z0-9 ]{4,25}"
        },
        'email': {
            type: String,
            label: "Email",
            placeholder: "Enter Email",
            icon: "ti-email",
            iconColor: "color-primary",
            dataType: "String",
            default: "",
            required: true,
            email: true
        },

        // 'phoneNo': {
        //     type: String,
        //     label: "Phone Number",
        //     placeholder: "Enter Phone Number",
        //     icon: "fas fa-phone",
        //     iconColor: "color-primary",
        //     dataType: "String",
        //     default: "",
        //     required: true,
        //     minLength: 7
        // },

    });
    const [formObj, setFormObj] = useState(null);
    const [validationRule, setValidationRule] = useState(null);
    const [errors, setErrors] = useState({});
    const [formError, setFormError] = useState(null);
    const [isLoading, setLoading] = useState(true);

    const changePasswordBluePrint = {
        'oldPassword': {
            type: String,
            label: `Old Password`,
            placeholder: `Enter Old Password`,
            icon: "ti-lock",
            iconColor: "color-primary",
            dataType: "PasswordHash",
            default: "",
            required: true,
            inputClass: "",
            minLength: 6
        },
        'newPassword': {
            type: String,
            label: `New Password`,
            placeholder: `Enter New Password`,
            icon: "ti-lock",
            iconColor: "color-primary",
            dataType: "PasswordHash",
            default: "",
            required: true,
            inputClass: "",
            minLength: 6
        }
    };

    const [changePasswordFormObj, setChangePasswordFormObj] = useState(null);
    const [changePasswordValidationRule, setChangePasswordValidationRule] = useState(null);
    const [changePasswordErrors, setChangePasswordErrors] = useState({});
    const [isChangePasswordLoading, setChangePasswordtLoading] = useState(true);
    const [purchases, setPurchases] = useState(null);

    const getPurchase = () => {
        apiService.filter('purchases', { query: { user: user._id }, sortQuery: { dateTime: -1 } })
            .then(setPurchases);

    }

    useEffect(() => {
        let tempSocket = null;
        if (user) {
            getPurchase();
            tempSocket = socketUpdater('purchase', null, null, (action, data) => {
                if ((action === "new" || action === "update") && data && data.user && data.user === user._id) {
                    getPurchase();
                }
            });
        }
        return () => {
            if (tempSocket) {
                tempSocket.socket.close();
            }

        }
    }, [user]);

    useEffect(() => {

        setUser(authenticationService.getUserLocal());

        let { mFormObj, mValidationRule } = generateValidation(bluePrint);
        setFormObj(mFormObj); setValidationRule(mValidationRule); setLoading(false);

        intializeChangePasswordInfo();
    }, []);

    const intializeChangePasswordInfo = () => {
        let { mFormObj, mValidationRule } = generateValidation(changePasswordBluePrint);
        setChangePasswordFormObj(mFormObj);
        setChangePasswordValidationRule(mValidationRule);
        setChangePasswordtLoading(false);
    }


    let loaded = false;
    useEffect(() => {
        if (user && !loaded) {
            loaded = true;
            setFormObj(user);
        }
    }, [user]);

    const handleChange = ({ target }) => {
        const { name, value } = target;
        handleChageCustom(name, value);
    }
    const handleChageCustom = (name, value) => {
        const errors = validate({ [name]: value }, { [name]: validationRule[name] });
        let obj = fillInObject(Object.assign({}, formObj), name, value);
        setFormObj(obj);
        setErrors(Object.assign({}, errors,
            errors ? errors : { [name]: undefined }
        ));
    }


    const handleChangePasswordChange = ({ target }) => {
        const { name, value } = target;
        handleChangePasswordChangeCustom(name, value);
    }
    const handleChangePasswordChangeCustom = (name, value) => {
        const errors = validate({ [name]: value }, { [name]: changePasswordValidationRule[name] });
        let obj = fillInObject(Object.assign({}, changePasswordFormObj), name, value);
        setChangePasswordFormObj(obj);
        setChangePasswordErrors(Object.assign({}, errors, errors ? errors : { [name]: undefined }));
    }
    const onImageChangeHandler = event => {
        onChangeHandler(event, toast).then(({ loadedImage, renderImage }) => {
            //render image
            handleChageCustom("profileImage", renderImage);
            //set for uploading to server
            setProfileImage(loadedImage);
        });
    }
    const handleSubmit = (e) => {
        e && e.preventDefault();
        const errors = validate(formObj, validationRule);
        if (errors) {
            toast.error("Please enter all required fields")
            setFormError(null);
            setErrors(errors);
            return;
        }

        setLoading(true);
        let form = new FormData();
        if (selectedProfileImage) {
            form.append('profileImage', selectedProfileImage)
        }
        form.append('name', formObj.name);
        form.append('email', formObj.email);
        form.append('financialPassword', formObj.financialPassword);

        authenticationService.update(form).then(res => {
            setLoading(false);
            toast.success("Profile Updated");
            localStorage.setItem("user", JSON.stringify(res));

            window.location.reload();
            // dispatch(authenticationActions.getCurrentUser())

        }).catch(err => {
            console.log(err.response.data);
            setLoading(false);
            toast.error(err.response.data.message);
        })
    }


    const handleChangePasswordSubmit = (e) => {
        e && e.preventDefault();
        const errors = validate(changePasswordFormObj, changePasswordValidationRule);
        if (errors) {
            toast.error("Please enter all required fields")
            setChangePasswordErrors(errors);
            return;
        }

        setChangePasswordtLoading(true);

        authenticationService.changePassword(changePasswordFormObj)
            .then(res => {
                setChangePasswordtLoading(false);
                toast.success("Success, Your Password has been changed!");
                // dispatch(authenticationActions.getCurrentUser());

                setTimeout(() => {
                    window.location.reload();
                }, 3000);

            }).catch(err => {
                setChangePasswordtLoading(false);
                toast.error(err.response.data.message);
            });

    }
    return (
        <Switch>
            <Page>
                {
                    user && formObj ? <div className='card-content-new profile-page'>
                        <div className='profile-main-container'>
                            <div className='row profile-container'>
                                <img src={getImageURL(formObj.profileImage)} alt="" className='profile-img' />

                                <div className='profile-content'>
                                    <span className='profile-name primary-text'>{user.name}</span>
                                    <span className='profile-email'>{user.email}</span>
                                    <input type="file" className="custom-file-input-p" name="file" onChange={onImageChangeHandler} />
                                </div>
                            </div>
                            <div className='profile-referral-container'>
                                <h3 className='profile-form-title'>Referral URL</h3>
                                <div className='d-h-referral-row'>
                                    <div className='d-h-referral-content'>
                                        <i className='fa fa-link'></i>
                                        <span className='ml-2'>{config.websiteURL}/signup/{user.name.toLowerCase()}</span>
                                    </div>
                                    <button className='btn primary-solid-btn d-h-referral-button ml-2'
                                        onClick={async () => {
                                            await navigator.clipboard.writeText(encodeURI(`${config.websiteURL}/signup/${user.name.toLowerCase()}`));
                                            toast.success("Referral URL copied to clipboard!");
                                        }}>
                                        Copy Link <i class="fas fa-clipboard ml-1"></i>
                                    </button>
                                </div>
                            </div>
                        </div>



                        <div className='row'>
                            <form onSubmit={handleSubmit} className="profile-form">
                                <h3 className='profile-form-title'>Basic Information</h3>
                                {formObj &&
                                    <FieldsGenerator
                                        errors={errors}
                                        formObj={formObj}
                                        handleChageCustom={handleChageCustom}
                                        handleChange={handleChange}
                                        loggingIn={false}
                                        isLoading={isLoading}
                                        bluePrint={bluePrint}
                                        key={0}
                                    />
                                }

                                {formError && <Alert severity="error" className="mt-3">{formError} </Alert>}

                                <button type="submit" class="btn btn-primary btn-block btn-lg mt-30" disabled={isLoading}>
                                    {isLoading ? "Please Wait..." : "Update Profile"}
                                </button>
                            </form>

                            <form onSubmit={handleChangePasswordSubmit} className="profile-form ">
                                <h3 className='profile-form-title'>Change Password</h3>
                                {formObj &&
                                    <FieldsGenerator
                                        errors={changePasswordErrors}
                                        formObj={changePasswordFormObj}
                                        handleChageCustom={handleChangePasswordChangeCustom}
                                        handleChange={handleChangePasswordChange}
                                        isLoading={isChangePasswordLoading}
                                        loggingIn={false}
                                        bluePrint={changePasswordBluePrint}
                                    />
                                }

                                {/* {changePasswordErrors && <Alert severity="error" className="mt-3">{changePasswordErrors} </Alert>} */}

                                <button type="submit" class="btn btn-primary btn-block btn-lg mt-30" disabled={isChangePasswordLoading}>
                                    {isChangePasswordLoading ? "Please Wait..." : "Submit"}
                                </button>
                            </form>

                        </div>
                    </div> : null
                }


                <BuyFromCard />

                <div className='purchase-history'>
                    <h3 className='profile-form-title'>Purchase History</h3>
                    {
                        purchases ?
                            <TableContainer component={Paper} className="mt-2">
                                <Table sx={{ minWidth: 650 }} aria-label="simple table" className="bg-market-trends mt-4">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Address</TableCell>
                                            <TableCell >Amount</TableCell>
                                            <TableCell >Mode</TableCell>
                                            <TableCell >Status</TableCell>
                                            <TableCell >Date Time</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {purchases.map((puchase, key) => <TableRow
                                            key={key}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell>{puchase.address}</TableCell>
                                            <TableCell>{currencyDisplayHelper(puchase.amount)}</TableCell>
                                            <TableCell>{puchase.mode}</TableCell>
                                            <TableCell>{puchase.status}</TableCell>
                                            <TableCell>{new Date(puchase.dateTime).toLocaleString()}</TableCell>
                                        </TableRow>)}
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            :
                            // <APILoader />
                            null
                    }
                </div>
            </Page>
        </Switch >
    );
};

export default Profile;