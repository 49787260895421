import React from 'react';

//Graveyard ecosystem logos
import LoonLogo from '../../assets/img/loon.png';
import tShareLogo from '../../assets/img/bulshares.png';
import bombLogoPNG from '../../assets/img/bomb.png';
import xbulLogo from '../../assets/img/xbul.png';

import tShareLogoPNG from '../../assets/img/bulshares.png';
import tBondLogo from '../../assets/img/bbond.png';

import bombFtmLpLogo from '../../assets/img/bomb-busd-lp.png';
import bulshareFtmLpLogo from '../../assets/img/bulshare-bnb-LP.png';
import usdcLogo from '../../assets/img/USDC.png';
import bnbLogo from '../../assets/img/bnb.png';
import btcLogo from '../../assets/img/BCTB-icon.png';
import dogeLogo from '../../assets/img/DOGE-icon.png';
import ethLogo from '../../assets/img/ETH-icon.png';
import ShibaLogo from '../../assets/img/SHIBA-icon.png';
import FtmLogo from '../../assets/img/WFTM.png';
import BusdLogo from '../../assets/img/BUSD.png';
import CARD from '../../assets/img/CARD.png';

const logosBySymbol: { [title: string]: string } = {
  //Real tokens
  //=====================
  LOON: LoonLogo,
  LOONPNG: bombLogoPNG,
  AKASHAREPNG: tShareLogoPNG,
  XLOON: xbulLogo,
  AKASHARE: tShareLogo,
  bLOON: tBondLogo,
  WBNB: bnbLogo,
  BOO: bnbLogo,
  SHIB: ShibaLogo,
  ZOO: bnbLogo,
  CAKE: bnbLogo,
  SUSD: bnbLogo,
  SBTC: btcLogo,
  BTCB: btcLogo,
  BTC: btcLogo,
  SVL: bnbLogo,
  DOGE: dogeLogo,
  ETH: ethLogo,
  USDC: usdcLogo,
  FTM: FtmLogo,
  BUSD: BusdLogo,
  'LOON-BUSD-LP': bombFtmLpLogo,
  'LOON-BTCB-LP': bombFtmLpLogo,
  'AKASHARE-BNB-LP': bulshareFtmLpLogo,
  'AKASHARE-BNB-APELP': bulshareFtmLpLogo,
  'LOON-BTCB-APELP': bombFtmLpLogo,
  CARD
};

type LogoProps = {
  symbol: string;
  size?: number;
  width?: number
};

const TokenSymbol: React.FC<LogoProps> = ({ symbol, size = 64, width = null }) => {
  if (!logosBySymbol[symbol]) {
    throw new Error(`Invalid Token Logo symbol: ${symbol}`);
  }
  return <img src={logosBySymbol[symbol]} alt={`${symbol} Logo`} width={width ? width : size} height={size} />;
};

export const TokenSymbolCustom: React.FC<LogoProps> = ({ symbol }) => {
  if (!logosBySymbol[symbol]) {
    throw new Error(`Invalid Token Logo symbol: ${symbol}`);
  }
  return <img src={logosBySymbol[symbol]} alt={`${symbol} Logo`} width={'100%'} height={'100%'} />;
};

export default TokenSymbol;
