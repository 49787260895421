import {useCallback, useEffect, useState} from 'react';

import {BigNumber} from 'ethers';
import useGldmFinance from './useLoonFinance';
import config from '../config';
import ERC20 from '../bomb-finance/ERC20';
import { getBalance } from '../utils/formatBalance';
import useLoonFinance from './useLoonFinance';
import useRefresh from './useRefresh';
import useWallet from 'use-wallet';

const useTotalNFTs = () => {
  const [total, setTotal] = useState(0);

  const bombFinance = useLoonFinance();
  const {slowRefresh} = useRefresh();

  useEffect(() => {
    async function fetchData() {
      try {
        setTotal(await bombFinance.getTotalNFTs());
      } catch (err) {
        console.error(err);
      }
    }
    fetchData();
  }, [setTotal, bombFinance, slowRefresh]);

  return total;
};

export const useNFTPrice = () => {
  const [price, setPrice] = useState(BigNumber.from(0));

  const bombFinance = useLoonFinance();
  const {slowRefresh} = useRefresh();

  useEffect(() => {
    async function fetchData() {
      try {
        setPrice(await bombFinance.getNFTPrice());
      } catch (err) {
        console.error(err);
      }
    }
    fetchData();
  }, [setPrice, bombFinance, slowRefresh]);

  return price;
};

export const useTokenIds = () => {
  const [tokenIds, setTokenIds] = useState([]);
  const {account} = useWallet()
  const bombFinance = useLoonFinance();
  const {slowRefresh} = useRefresh();

  useEffect(() => {
    async function fetchData() {
      try {
        setTokenIds(await bombFinance.getTokenIds(account));
      } catch (err) {
        console.error(err);
      }
    }
    fetchData();
  }, [setTokenIds, bombFinance, slowRefresh, account]);

  return tokenIds;
};



export const useStakedIds = () => {
  const [stakedId, setStakedId] = useState([]);
  const {account} = useWallet()
  const bombFinance = useLoonFinance();
  const {slowRefresh} = useRefresh();

  useEffect(() => {
    async function fetchData() {
      try {
        const temp  = await bombFinance.getStakedIds(account);
        setStakedId(temp)
      } catch (err) {
        console.error(err);
      }
    }

    fetchData()
    
  }, [setStakedId, account, bombFinance, slowRefresh])

  return stakedId;
}


// export const useNFTSupply = () => {
//   const [supply, setSupply] = useState(0);

//   const bombFinance = useLoonFinance();
//   const {slowRefresh} = useRefresh();

//   useEffect(() => {
//     async function fetchData() {
//       try {
//         setSupply(await bombFinance.getNFTSupply());
//       } catch (err) {
//         console.error(err);
//       }
//     }
//     fetchData();
//   }, [setSupply, bombFinance, slowRefresh]);

//   return supply;
// };

export const useNFTBalance = () => {
  const [price, setPrice] = useState(BigNumber.from(0));
  const {account} = useWallet()
  const bombFinance = useLoonFinance();
  const {slowRefresh} = useRefresh();

  useEffect(() => {
    async function fetchData() {
      try {
        setPrice(await bombFinance.getNFTBalance(account));
      } catch (err) {
        console.error(err);
      }
    }
    fetchData();
  }, [setPrice, bombFinance, slowRefresh]);

  return price;
};



export default useTotalNFTs;
