export const LOON_TICKER = 'LOON';
export const AKASHARE_TICKER = 'AKASHARE';
export const bLOON_TICKER = 'bLOON';
export const BNB_TICKER = 'BNB';
export const WBNB_TICKER = 'WBNB';
export const BTC_TICKER = 'BTCB';
export const BUSD_TICKER = 'BUSD';

export const SPOOKY_ROUTER_ADDR = '0x10ED43C718714eb63d5aA57B78B54704E256024E';
export const ZAPPER_ROUTER_ADDR = '0x25c45E77712A2A462bBbD735ECA5F9AFEE493950';
export const TAX_OFFICE_ADDR = '0x2A637BEE0D76136d8ee44B96cf0A5f9198657AaF';
