import React, {useMemo} from 'react';
import styled from 'styled-components';
import useTokenBalance from '../../hooks/useTokenBalance';
import {getDisplayBalance} from '../../utils/formatBalance';

import Label from '../Label';
import Modal, {ModalProps} from '../Modal';
import ModalTitle from '../ModalTitle';
import useLoonFinance from '../../hooks/useLoonFinance';
import TokenSymbol from '../TokenSymbol';
import {useMediaQuery} from '@material-ui/core';
import { useNFTBalance } from '../../hooks/useTotalNFTs';

const AccountModal: React.FC<ModalProps> = ({onDismiss}) => {
  const bombFinance = useLoonFinance();

  const bombBalance = useTokenBalance(bombFinance.LOON);
  const displayLoonBalance = useMemo(() => getDisplayBalance(bombBalance, 18, 2), [bombBalance]);

  const bulshareBalance = useTokenBalance(bombFinance.BulSHARE);//changepart
  const displayLOONshareBalance = useMemo(() => getDisplayBalance(bulshareBalance, 18, 2), [bulshareBalance]);

  const nftBalance = useNFTBalance();


  const bbondBalance = useTokenBalance(bombFinance.bLOON);
  const displayBbondBalance = useMemo(() => getDisplayBalance(bbondBalance, 18, 2), [bbondBalance]);

  const xbulBalance = useTokenBalance(bombFinance.XLOON);
  const displayXbulBalance = useMemo(() => getDisplayBalance(xbulBalance, 18, 2), [xbulBalance]);

  const matches = useMediaQuery('(min-width:900px)');

  return (
    <Modal>
      <ModalTitle text="My Wallet" />

      <Balances style={{display: 'flex', flexDirection: matches ? 'row' : 'column'}}>
        <StyledBalanceWrapper style={{paddingBottom: '15px'}}>
          <TokenSymbol symbol="LOON" />
          <StyledBalance>
            <StyledValue>{displayLoonBalance}</StyledValue>
            <Label text="$USDB Available" />
          </StyledBalance>
        </StyledBalanceWrapper>

        <StyledBalanceWrapper style={{paddingBottom: '15px'}}>
          <TokenSymbol symbol="AKASHARE" />
          <StyledBalance>
            <StyledValue>{nftBalance.toString()}</StyledValue>
            <Label text="APES Available" />
          </StyledBalance>
        </StyledBalanceWrapper>
       
        <StyledBalanceWrapper style={{paddingBottom: '15px'}}>
          <TokenSymbol symbol="bLOON" />
          <StyledBalance>
            <StyledValue>{displayBbondBalance}</StyledValue>
            <Label text="USDBb Available" />
          </StyledBalance>
        </StyledBalanceWrapper>
      </Balances>
    </Modal>
  );
};

const StyledValue = styled.div`
  //color: ${(props) => props.theme.color.grey[300]};
  font-size: 30px;
  font-weight: 700;
`;

const StyledBalance = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const Balances = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: ${(props) => props.theme.spacing[4]}px;
`;

const StyledBalanceWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0 ${(props) => props.theme.spacing[3]}px;
`;

export default AccountModal;
