import _config from '../_config';
import Axios from 'axios';
import { authHeader } from '../_helpers/auth-header';
import qs from 'qs';
export const apiService = {
    type: function (route) {
        const requestOptions = { headers: authHeader() };
        return Axios.get(`${_config.baseUrl}/api/user/${route}/b/type`, requestOptions).then(handleResponse);
    },
    getAll: function (route, params) {
        const requestOptions = { headers: authHeader() };
        let url = `${_config.baseUrl}/api/user/${route}`;
        if (params)
            url += `?${qs.stringify(params)}`;
        return Axios.get(url, requestOptions).then(handleResponse);
    },
    getWithPagination: function (route, page) {
        const requestOptions = { headers: authHeader() };
        return Axios.get(`${_config.baseUrl}/api/user/${route}/pagination/data?page=${page ? page : 1}`, requestOptions).then(handleResponse);
    },
    filter: function (route, data, pageOptions = null) {
        const requestOptions = { headers: authHeader() };
        if (pageOptions) {
            data = { ...data, pageOptions };
        }
        return Axios.post(`${_config.baseUrl}/api/user/${route}/filter`, data, requestOptions).then(handleResponse);
    },
    filterNear: function (route, data) {
        const requestOptions = { headers: authHeader() };
        return Axios.post(`${_config.baseUrl}/api/user/${route}/nearby`, data, requestOptions).then(handleResponse);
    },
    getSingle: function (route, id) {
        const requestOptions = { headers: authHeader() };
        return Axios.get(`${_config.baseUrl}/api/user/${route}/${id}`, requestOptions).then(handleResponse);
    },
    add: function (route, data) {
        const requestOptions = { headers: authHeader() };
        return Axios.post(`${_config.baseUrl}/api/user/${route}`, data, requestOptions).then(handleResponse);
    },
    update: function (route, data, id) {
        const requestOptions = { headers: authHeader() };
        return Axios.put(`${_config.baseUrl}/api/user/${route}/${id}`, data, requestOptions).then(handleResponse);
    },
    delete: function (route, id) {
        const requestOptions = { headers: authHeader() };
        return Axios.delete(`${_config.baseUrl}/api/user/${route}/${id}`, requestOptions).then(handleResponse);
    }
};




function handleResponse(response) {
    return response.data;
}