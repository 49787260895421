import React, { useEffect } from 'react';
import { authenticationService } from '../../_services/authentication.service';

const Logout = ({history}) => {
    useEffect(() => {
        authenticationService.logout();
        history.push("/login");
    }, []);
    
    return (
        <div>
            
        </div>
    );
};

export default Logout;