
import React from 'react';
import { Switch } from 'react-router-dom';
import Page from '../../components/Page';
import BuyFromCard from './BuyFromCard';

const Purchase = () => {
  

    return (
        <Switch>
            <Page>
                <BuyFromCard />
            </Page>
        </Switch>
    );
};

export default Purchase;