import { Alert } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { validate } from 'validate.js';
import AnonymousLayout from '../../components/AnonymousLayout';
import FieldsGenerator from '../../components/FieldsGenerator';
import config from '../../_config';
import fillInObject from '../../_helpers/fillInObject';
import generateValidation from '../../_helpers/generateValidation';
import { authenticationService } from '../../_services/authentication.service';

const Signup = ({ history, match }) => {

    const { parent } = match.params;
    const [bluePrint, setBluePrint] = useState({
        'name': {
            type: String,
            label: "User Name",
            placeholder: "Enter User Name",
            dataType: "String",
            displayOnHeader: true,
            default: "",
            pattern: "^(?!.*\\.\\.)(?!.*\\.$)[^\\W][\\w.]{0,29}$",
            required: true
        },
        'email': {
            type: String,
            label: "Email",
            placeholder: "Enter Email",
            icon: "ti-email",
            iconColor: "color-primary",
            dataType: "String",
            default: "",
            required: true,
            email: true
        },
        'password': {
            type: String,
            label: "Password",
            placeholder: "Enter Password",
            icon: "ti-lock",
            iconColor: "color-primary",
            dataType: "PasswordHash",
            default: "",
            required: true,
        },
        // 'financialPassword': {
        //     type: String,
        //     label: "Transaction Code",
        //     placeholder: "Define Transaction Code",
        //     dataType: "PasswordHash",
        //     displayOnHeader: false,
        //     default: "",
        //     required: true
        // },
        'upline': {
            type: String,
            label: "Parent",
            placeholder: "Enter Parent",
            dataType: "String",
            default: "",
            hidden: true
        },

    });
    const [formObj, setFormObj] = useState(null);
    const [formError, setFormError] = useState(null);
    const [validationRule, setValidationRule] = useState(null);
    const [errors, setErrors] = useState({});
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        document.title = `Signup | ${config.projectName}`;

    }, []);

    useEffect(() => {
        let { mFormObj, mValidationRule } = generateValidation(bluePrint);
        if (parent) {
            mFormObj.upline = parent;
        }
        setFormObj(mFormObj); setValidationRule(mValidationRule); setLoading(false);
    }, []);

    const handleChange = ({ target }) => {
        const { name, value } = target;
        handleChageCustom(name, value);
    }
    const handleChageCustom = (name, value) => {
        const errors = validate({ [name]: value }, { [name]: validationRule[name] });
        let obj = fillInObject(Object.assign({}, formObj), name, value);
        setFormObj(obj);
        setErrors(Object.assign({}, errors,
            errors ? errors : { [name]: undefined }
        ));
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setFormError(null);
        const errors = validate(formObj, validationRule);
        if (errors) {
            setFormError("Please enter all required fields");
            setErrors(errors);
            return;
        }

        delete formObj["_id"];

        setLoading(true);
        authenticationService.register(formObj)
            .then(m => {
                history.push(`/email/register-success/${m.email}`)
            })
            .catch(err => {
                setFormError(err.response.data.message);
                setLoading(false);
            })
    }

    return (
        <AnonymousLayout>
            <h2 class="brand-text font-size-18 mt-4">Register</h2>

            <form onSubmit={handleSubmit}>
                {formObj &&
                    <FieldsGenerator
                        errors={errors}
                        formObj={formObj}
                        handleChageCustom={handleChageCustom}
                        handleChange={handleChange}
                        loggingIn={false}
                        isLoading={isLoading}
                        bluePrint={bluePrint}
                        key={0}
                    />
                }

                {formError && <Alert severity="error" className="mt-3">{formError} </Alert>}

                <button type="submit" class="btn btn-primary btn-block btn-lg mt-30" disabled={isLoading}>
                    {isLoading ? "Please Wait..." : "Register"}
                </button>
            </form>

            <div className="text-center mt-4">
                <small>Already have an acocunt? </small>
                <Link to="/login" className="small primary-text">Login</Link>
            </div>

        </AnonymousLayout >
    );
};

export default Signup;