
import { Input } from '@material-ui/core';
import React, { Component, Fragment } from 'react';
import Field from '../../_common/field';
import './style.css'
export default class PasswordField extends Component {
    state = {
        value: "",
        visible: false
    }

    componentDidMount() {
        this.setState({ value: this.props.value })
        if (this.props.value)
            this.handleInputChange({ target: { name: this.props.name, value: this.props.value } });
    }
    handleInputChange = ({ target }) => {
        if (!this.props.disabled) {
            const { name, value } = target;
            this.triggerOnChange(value)
        }
    }
    triggerOnChange = (value) => {
        this.setState({ value: value })
        if (this.props.onChange) {
            this.props.onChange(value);
        }
    }
    render() {
        const { name, placeholder, disabled, errors, attribute, fclass } = this.props;
        const { visible } = this.state;
        return (

            <div className="password-form-container">
                <Field {...this.props} errors={errors} attribute={attribute} fieldClass={`${fclass} password-form-field`}>
                    <Input
                        name={name}
                        type={visible ? "text" : "password"}
                        // className="form-control"
                        placeholder={placeholder}
                        // value={this.state.value}
                        disabled={disabled}
                        onChange={this.handleInputChange}
                    />
                </Field>

                <div className='password-icon'  onClick={() => this.setState({ visible: !visible })}> 
                    <i className={`far fa-${visible ? 'eye' : 'eye-slash'}`}></i>
                </div>
            </div>

        );
    }
}