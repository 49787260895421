import { Alert } from '@material-ui/lab';
import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { validate } from 'validate.js';
import FieldsGenerator from '../../components/FieldsGenerator';
import _config from '../../_config';
import { authenticationActions } from '../../_actions/authentication.actions';
import fillInObject from '../../_helpers/fillInObject';
import generateValidation from '../../_helpers/generateValidation';
import './style.css';
import { authenticationService } from '../../_services/authentication.service';
import AnonymousLayout from '../../components/AnonymousLayout';

const Login = ({ history }) => {
    const [step, setStep] = useState("login");
    const [bluePrint, setBluePrint] = useState({
        'email': {
            type: String,
            label: "Email or Username",
            placeholder: "Enter Email or Username",
            dataType: "String",
            default: "",
            required: true
        },
        'password': {
            type: String,
            label: "Password",
            placeholder: "Enter Password",
            dataType: "PasswordHash",
            default: "",
            required: true,
        },

    });
    const [formObj, setFormObj] = useState(null);
    const [validationRule, setValidationRule] = useState(null);
    const [errors, setErrors] = useState({});
    const [formError, setFormError] = useState(null);
    const [isLoading, setLoading] = useState(true);

    const [twoFABluePrint, setTwoFABluePrint] = useState({
        'twoFACode': {
            type: String,
            label: "Authentication Code",
            placeholder: "Authentication Code",
            dataType: "String",
            default: "",
            required: true,
            minLength: 6
        }
    });
    const [twoFAFormObj, setTwoFAFormObj] = useState(null);
    const [twoFAValidationRule, setTwoFAValidationRule] = useState(null);
    const [twoFAErrors, setTwoFAErrors] = useState({});

    useEffect(() => {
        document.title = `Login | ${_config.projectName}`;

    }, []);

    useEffect(() => {
        let { mFormObj, mValidationRule } = generateValidation(bluePrint);
        setFormObj(mFormObj); setValidationRule(mValidationRule); setLoading(false);
        intialize2FAForm();
    }, []);

    const intialize2FAForm = () => {
        let { mFormObj, mValidationRule } = generateValidation(twoFABluePrint);
        setTwoFAFormObj(mFormObj);
        setTwoFAValidationRule(mValidationRule);
    }

    const handleChange = ({ target }) => {
        const { name, value } = target;
        handleChageCustom(name, value);
    }
    const handleChageCustom = (name, value) => {
        const errors = validate({ [name]: value }, { [name]: validationRule[name] });
        let obj = fillInObject(Object.assign({}, formObj), name, value);
        setFormObj(obj);
        setErrors(Object.assign({}, errors,
            errors ? errors : { [name]: undefined }
        ));
    }

    const handleTwoFAChange = ({ target }) => {
        const { name, value } = target;
        const errors = validate({ [name]: value }, { [name]: twoFAValidationRule[name] });
        let obj = fillInObject(Object.assign({}, twoFAFormObj), name, value);
        setTwoFAFormObj(obj);
        setTwoFAErrors(Object.assign({}, errors,
            errors ? errors : { [name]: undefined }
        ));
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const errors = validate(formObj, validationRule);
        if (errors) {
            toast.error("Please enter all required fields")
            setFormError(null);
            setErrors(errors);
            return;
        }

        delete formObj["_id"];
        if (step === "require-2fa-auth") {
            formObj["twoFACode"] = twoFAFormObj.twoFACode;
        }


        setLoading(true);
        authenticationService.login(formObj).then(token => {
            setLoading(false);
            history.push("/");

        }).catch(e => {
            let error = e.response ? e.response.data.message : e.message;

            console.log({ response: error });
            setLoading(false);
            if (error === "Your Email is not verified yet!") {
                setFormError(null)
                history.push(`/email/unverified-email/${formObj.email}`);
                return;
            }
            else if (error === "require-2fa-auth") {
                setFormError(null)
                // history.push(`/verify-2fa/${btoa(password)}/${email}`);
                return;
            }
            setFormError(error === "Network Error" ? "Internet Problem" : error);
        })
        // dispatch(authenticationActions.login(formObj, history, setLoading, handleCallbackError));
    }
    const handleCallbackError = (error) => {
        console.log({ error });
        if (error === "require-2fa-auth") {
            setStep(error);
        }
    }

    return (
        <AnonymousLayout>
            <h2 class="brand-text font-size-18 mt-4">{step === "login" ? "Welcome Back!" : "Two-factor Authentication"}</h2>
            {
                step === "login" ?
                    <form onSubmit={handleSubmit}>
                        {formObj &&
                            <FieldsGenerator
                                errors={errors}
                                formObj={formObj}
                                handleChageCustom={handleChageCustom}
                                handleChange={handleChange}
                                loggingIn={false}
                                isLoading={isLoading}
                                bluePrint={bluePrint}
                                key={0}
                            />
                        }

                        <Link className='text-right pr-2 primary-text' to="/forgot-password">
                            Forgot Password?
                        </Link>

                        {formError && <Alert severity="error" className="mt-3">{formError} </Alert>}

                        <button type="submit" class="btn btn-primary btn-block btn-lg mt-30" disabled={isLoading}>
                            {isLoading ? "Please Wait..." : "Sign in"}
                        </button>
                    </form> :
                    <form className="login-signup-form" onSubmit={handleSubmit}>
                        {twoFABluePrint &&
                            <FieldsGenerator
                                errors={twoFAErrors}
                                formObj={twoFAFormObj}
                                // handleChageCustom={handleChageCustom}
                                handleChange={handleTwoFAChange}
                                loggingIn={false}
                                isLoading={isLoading}
                                bluePrint={twoFABluePrint}
                                key={0}
                            />
                        }
                        {alert.message && <Alert severity={alert.type}>{alert.message} </Alert>}


                        <button type="submit" class="btn btn-primary btn-block btn-lg mt-30" disabled={isLoading}>
                            {isLoading ? "Please Wait..." : "Submit"}
                        </button>
                    </form>
            }
            <p className='mt-4 form-navigation-text'>Still no account? Please go to <Link to="/signup" className='primary-text'>Sign up</Link></p>

        </AnonymousLayout>
    );
};

export default Login;