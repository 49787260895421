import React, { Fragment, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { validate } from 'validate.js';
import AnonymousLayout from '../../components/AnonymousLayout';
import FieldsGenerator from '../../components/FieldsGenerator';
import config from '../../config';
import fillInObject from '../../_helpers/fillInObject';
import generateValidation from '../../_helpers/generateValidation';
import { authenticationService } from '../../_services/authentication.service';

const ForgotPassword = ({ history }) => {

    const [bluePrint, setBluePrint] = useState({
        'email': {
            type: String,
            label: "Email",
            placeholder: "Enter Email",
            icon: "ti-email",
            iconColor: "color-primary",
            dataType: "String",
            default: "",
            required: true,
            email: true
        },


    });
    const [formObj, setFormObj] = useState(null);
    const [validationRule, setValidationRule] = useState(null);
    const [errors, setErrors] = useState({});
    const [formError, setFormError] = useState(null);
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {

        document.title = `Forgot Password | ${config.projectName}`;
        let { mFormObj, mValidationRule } = generateValidation(bluePrint);
        setFormObj(mFormObj); setValidationRule(mValidationRule); setLoading(false);
    }, []);

    const handleChange = ({ target }) => {
        const { name, value } = target;
        handleChageCustom(name, value);
    }
    const handleChageCustom = (name, value) => {
        const errors = validate({ [name]: value }, { [name]: validationRule[name] });
        let obj = fillInObject(Object.assign({}, formObj), name, value);
        setFormObj(obj);
        setErrors(Object.assign({}, errors,
            errors ? errors : { [name]: undefined }
        ));
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const errors = validate(formObj, validationRule);
        if (errors) {
            toast.error("Please enter all required fields")
            setFormError(null);
            setErrors(errors);
            return;
        }

        delete formObj["_id"];
        setLoading(true);
        // dispatch(authenticationActions.login(formObj, history, match, setLoading, handleCallbackError));
        authenticationService.sendResetPasswordEmail(formObj.email)
            .then(naviageToLogin)
            .catch(e => {
                setLoading(false);
                toast.error(e.response.data.message)
                // naviageToLogin(e.response.data);
            })
    }
    const naviageToLogin = (res) => {
        setLoading(false);
        toast.success(res.message);
        history.push("/login")
    }
    return (
        <AnonymousLayout>
            <h2 class="brand-text font-size-18 mt-4">Forgot Password</h2>
            <form onSubmit={handleSubmit}>
                {formObj &&
                    <FieldsGenerator
                        errors={errors}
                        formObj={formObj}
                        handleChageCustom={handleChageCustom}
                        handleChange={handleChange}
                        loggingIn={false}
                        isLoading={isLoading}
                        bluePrint={bluePrint}
                        key={0}
                    />
                }
                <button type="submit" class="btn btn-primary btn-block btn-lg mt-30" disabled={isLoading}>
                    {isLoading ? "Please Wait..." : "Submit"}
                </button>
            </form>

            <p className='mt-4 form-navigation-text'>Back to <Link to="/login" className='primary-text'>Login</Link></p>
        </AnonymousLayout>
    );
};

export default ForgotPassword;