import React from 'react';
import { Switch } from 'react-router-dom';
import Page from '../../components/Page';
import Refferal from '../../components/Referral';

const Refferals = ({ history, match }) => {

    const { pageId } = match ? match.params : { pageId: null };
    return (
        <Switch>
            <Page>
                <Refferal history={history} pageId={pageId} />
            </Page>
        </Switch>
    );
};

export default Refferals;