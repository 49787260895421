import { FormControl, FormHelperText, InputLabel } from '@material-ui/core';
import React, { useEffect } from 'react';
const Field = ({ errors, label, labelHidden, icon, iconColor, inputClass, children, labelBr, fieldClass, attribute }) => {
    // return (
    //     <div className={`form-group ${fieldClass}`}>
    //         {label && !labelHidden && <label className="lightest-gray pb-1">{label}</label>}
    //         <div className="input-group input-group-merge">
    //             {React.cloneElement(children, {
    //                 className: `form-control ${inputClass && inputClass} ${errors ? 'is-danger' : ''}`
    //             })}
    //         </div>
    //         {errors ? <div className="alert alert-danger">{errors[0]}</div> : null}
    //     </div>
    // )
    return (
        <FormControl color="primary" variant="standard" error={errors ? true : false} className={`${fieldClass ? fieldClass : ''} mb-2`}>
            {label && !labelHidden && <InputLabel htmlFor={attribute}>{label}</InputLabel>}
            {children}
            {errors ? <FormHelperText id={`${attribute}-error-text`}>{errors[0]}</FormHelperText> : null}
        </FormControl>
    )
};

export default Field;
