import React, { useMemo } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import {
  AppBar,
  Box,
  Drawer,
  IconButton,
  Toolbar,
  Typography,
  useMediaQuery,
  List,
  ListItem,
  ListItemText,
  Divider,
} from '@material-ui/core';

import ListItemLink from '../ListItemLink';
import useLoonStats from '../../hooks/useLoonStats';
import useBtcStats from '../../hooks/useBtcStats';
import useBnbStats from '../../hooks/useBnbStats';
import useShareStats from '../../hooks/usebulshareStats';
import { getFullDisplayBalance, getBalance } from '../../utils/formatBalance'

import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AccountButton from './AccountButton';

import bombLogo from '../../assets/img/logoWithText.png';
import kycLogo from '../../assets/img/KYC_assure.png';
import { roundAndFormatNumber } from '../../0x';
import { useNFTPrice } from '../../hooks/useTotalNFTs';
//import TokenSymbol from '../TokenSymbol';

const useStyles = makeStyles((theme) => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
  },
  appBar: {
    color: '#25A4CF',
    'background-color': '#186a76',
    // borderBottom: `1px solid ${theme.palette.divider}`,
    padding: '10px',
    marginBottom: '3rem',
    backgroundColor: 'transparent',
    backdropFilter: 'blur(16px)',
  },
  drawer: {
    width: 240,
    flexShrink: 0,
  },
  drawerPaper: {
    width: 240,
  },
  hide: {
    display: 'none',
  },
  toolbar: {
    flexWrap: 'wrap',
  },
  toolbarTitle: {
    fontFamily: 'Facundo',
    fontSize: '0px',
    flexGrow: 1,
  },
  link: {
    textTransform: 'uppercase',
    color: '#25A4CF',
    fontSize: '18px',
    marginTop: '15px',
    margin: theme.spacing(10, 1, 1, 2),
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  brandLink: {
    textDecoration: 'none',
    color: '#25A4CF',
    '&:hover': {
      textDecoration: 'none',
    },
  },
}));

const Nav = () => {
  const matches = useMediaQuery('(min-width:900px)');
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const bombStats = useLoonStats();
  const btcStats = useBtcStats();
  const bnbStats = useBnbStats();
  const shareStats = useShareStats();
  const nftPrice = useNFTPrice()

  // const [connected, setConnected] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const btcPriceInDollars = useMemo(() => (bnbStats ? Number(bnbStats) : null), [bnbStats]);
  const bombPriceInDollars = useMemo(
    () => (bombStats ? Number(bombStats.priceInDollars).toFixed(2) : null),
    [bombStats],
  );
  // const sharePriceInDollars = useMemo(
  //   () => (shareStats ? Number(shareStats.priceInDollars).toFixed(2) : null),
  //   [shareStats],
  // );
  return (
    <AppBar position="sticky" elevation={0} className={classes.appBar} style={{ alignItems: 'center' }}>
      <Toolbar className={classes.toolbar} >
        {matches ? (
          <>

            <Typography variant="h6" color="inherit" noWrap style={{ flexGrow: '0' }} className={classes.toolbarTitle}>
              {/* <a className={ classes.brandLink } href="/">Airbrick APES</a> */}
              <Link to="/" color="inherit" className={classes.brandLink}>
                <img alt="bul.finance" src={bombLogo} height="50px" />
              </Link>
            </Typography>
            <Box style={{ paddingLeft: '15px', paddingTop: '10px', fontSize: '1rem', flexGrow: '1' }}>
              <Link to="/" className={'navLink ' + classes.link}>
                Dashboard
              </Link>
              <Link to="/farm/LoonBusdLPLoonRewardPool" className={'navLink ' + classes.link}>
                Farm
              </Link>
              <Link to="/boardroom" className={'navLink ' + classes.link}>
                Boardroom
              </Link>
              <Link to="/mint" className={'navLink ' + classes.link}>
                NFT Mint
              </Link>
              <Link to="/bond" className={'navLink ' + classes.link}>
                Bond
              </Link>

              <Link to="/referrals" className={'navLink ' + classes.link}>
                Refferals
              </Link>

              {/* <Link to="/purchase" className={'navLink ' + classes.link}>
                Purchase
              </Link> */}

              {/* <Link to="/logout" className={'navLink ' + classes.link}>
                Logout
              </Link> */}

              {/* <Link to="/Dao" className={'navLink ' + classes.link}>
                Dao
              </Link> */}

              {/* <Link color="textPrimary" to="/sbs" className={classes.link}>
                SBS
              </Link>
              <Link color="textPrimary" to="/liquidity" className={classes.link}>
                Liquidity
              </Link>
              <Link color="textPrimary" to="/regulations" className={classes.link}>
                Regulations
              </Link> */}
              {/* <a href="" className={'navLink ' + classes.link} rel="noopener noreferrer" target="_blank">
                Docs
              </a> */}
              {/* <a href="https://bomb.farm" className={'navLink ' + classes.link} rel="noopener noreferrer" target="_blank">
                AutoVaults
              </a> */}
            </Box>
            <Box
              style={{
                flexGrow: '0',
                paddingLeft: '15px',
                paddingTop: '5px',
                fontSize: '1rem',
                paddingRight: '15px',
                height: '30px',
                display: 'flex',
              }}
            >
              {/* <div className="navTokenIcon bomb"></div>{' '}
              <div className="navTokenPrice">${roundAndFormatNumber(Number(bombPriceInDollars), 2)}</div>
              <div className="navTokenIcon bulshare"></div>{' '}
              <div className="navTokenPrice">${roundAndFormatNumber(Number(getFullDisplayBalance(nftPrice)), 2)}</div> */}


              <Link to="/profile" className='nav-user-icon'>
                <i className='fa fa-user' />
              </Link>

              <Link to="/logout" className='nav-user-icon ml-2'>
                <i className='fa fa-power-off' />
              </Link>

              {/* <div className="navTokenIcon btc"></div>{' '} */}
              {/* <div className="navTokenPrice">${roundAndFormatNumber(Number(btcPriceInDollars), 3)}</div> */}
            </Box>
            <AccountButton text="Connect" />
          </>
        ) : (
          <>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(open && classes.hide)}
            >
              <MenuIcon />
            </IconButton>

            <img
              alt="bul.finance"
              src={bombLogo}
              style={{ height: '40px', marginTop: '-10px', marginLeft: '10px', marginRight: '15px' }}
            />
            <AccountButton text="Connect" />
            <Drawer
              className={classes.drawer}
              onClose={handleDrawerClose}
              // onEscapeKeyDown={handleDrawerClose}
              // onBackdropClick={handleDrawerClose}
              variant="temporary"
              anchor="left"
              open={open}
              classes={{
                paper: classes.drawerPaper,
              }}
            >
              <div>
                <IconButton onClick={handleDrawerClose}>
                  {theme.direction === 'rtl' ? (
                    <ChevronRightIcon htmlColor="white" />
                  ) : (
                    <ChevronLeftIcon htmlColor="white" />
                  )}
                </IconButton>
              </div>
              <Divider />
              <List>
                <ListItem>
                  <AccountButton text="Connect" />
                </ListItem>
                <ListItemLink primary="Home" to="/" />
                <ListItemLink primary="Farm" to="/farm/LoonBusdLPLoonRewardPool" />
                <ListItemLink primary="NFT mint" to="/mint" />
                <ListItemLink primary="Boardroom" to="/boardroom" />
                <ListItemLink primary="Bond" to="/bond" />
                <ListItemLink primary="Dao" to="/" />
                <ListItemLink primary="Referrals" to="/referrals" />
                <ListItemLink primary="Profile" to="/profile" />
                <ListItemLink primary="Logout" to="/logout" />

                {/* <ListItemLink primary="SBS" to="/sbs" /> */}
                {/* <ListItemLink primary="Liquidity" to="/liquidity" /> */}
                {/* <ListItemLink primary="Regulations" to="/regulations" /> */}
                {/* <ListItem button component="a" href="https://bomb.farm">
                  <ListItemText>AutoVaults</ListItemText>
                </ListItem> */}
              </List>
            </Drawer>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Nav;
