import React, { Suspense, lazy, Fragment } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ThemeProvider as TP } from '@material-ui/core/styles';
import { ThemeProvider as TP1 } from 'styled-components';
import { UseWalletProvider } from 'use-wallet';
import usePromptNetwork from './hooks/useNetworkPrompt';
import BanksProvider from './contexts/Banks';
import LoonFinanceProvider from './contexts/LoonFinanceProvider';
import ModalsProvider from './contexts/Modals';
import store from './state';
import theme from './theme';
import newTheme from './newTheme';
import config from './config';
import Updaters from './state/Updaters';
import Loader from './components/Loader';
import Popups from './components/Popups';
//import Regulations from './views/Regulations/Regulations';
import { RefreshContextProvider } from './contexts/RefreshContext';
import './assets/style.css';
import Login from './views/Login';
import { AnonymousRoute } from './_middlewares/_routeMiddleware/AnonymousRoute';
import { AuthenticatedRoute } from './_middlewares/_routeMiddleware/AuthenticatedRoute';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Signup from './views/Signup';
import EmailVerification from './views/EmailVerification';
import ForgotPassword from './views/ForgotPassword';
import Profile from './profile';
import Logout from './views/Logout';
import Purchase from './views/Purchase';
import Refferals from './views/Refferals';
import Withdraw from './views/Withdraw';


const Home = lazy(() => import('./views/Home'));
const Farm = lazy(() => import('./views/Farm'));
const Boardroom = lazy(() => import('./views/Boardroom'));
const Bond = lazy(() => import('./views/Bond'));
const NFT = lazy(() => import('./views/NFT'));
// const SBS = lazy(() => import('./views/Sbs'));
// const Liquidity = lazy(() => import('./views/Liquidity'));

const NoMatch = () => (
  <h3 style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
    URL Not Found. <a href="/">Go back home.</a>
  </h3>
);

const App: React.FC = () => {
  // Clear localStorage for mobile users
  if (typeof localStorage.version_app === 'undefined' || localStorage.version_app !== '1.1') {
    localStorage.clear();
    localStorage.setItem('connectorId', '');
    localStorage.setItem('version_app', '1.1');
  }

  usePromptNetwork();

  return (
    <Fragment>

      <ToastContainer className="tostify-mar"></ToastContainer>


      <Providers>
        <Router>
          <Suspense fallback={<Loader />}>
            <Switch>
              <AnonymousRoute path="/login" component={Login}></AnonymousRoute>


              <AnonymousRoute exact path="/signup" component={Signup} />

              <AnonymousRoute exact path="/signup/:parent" component={Signup} />

              <AnonymousRoute path='/email/:type/:email' component={EmailVerification} />

              <AnonymousRoute exact path="/forgot-password" component={ForgotPassword} />

              <AuthenticatedRoute exact path="/" component={Home} />

              <AuthenticatedRoute path="/farm" component={Farm} />

              <AuthenticatedRoute path="/boardroom" component={Boardroom} />

              <AuthenticatedRoute path="/bond" component={Bond} />

              <AuthenticatedRoute path="/mint" component={NFT} />

              <AuthenticatedRoute path="/dao" component={NFT} />

              <AuthenticatedRoute path="/purchase" component={Purchase} />

              <AuthenticatedRoute path="/profile" component={Profile} />

              <AuthenticatedRoute path="/referrals" component={Refferals} />

              <AuthenticatedRoute path="/referrals/:pageId" component={Refferals} />

              <AuthenticatedRoute path="/withdraw" component={Withdraw} />

              <AuthenticatedRoute path="/logout" component={Logout} />


              {/* <Route path="/sbs">
              <SBS />
            </Route> */}
              {/* <Route path="/regulations">
              <Regulations />
            </Route> */}
              {/* <Route path="/liquidity">
              <Liquidity />
            </Route> */}
              <Route path="*">
                <NoMatch />
              </Route>
            </Switch>
          </Suspense>
        </Router>
      </Providers>
    </Fragment>
  );
};

const Providers: React.FC = ({ children }) => {
  return (
    <TP1 theme={theme}>
      <TP theme={newTheme}>
        <UseWalletProvider
          chainId={config.chainId}

          connectors={{
            walletconnect: { rpcUrl: config.defaultProvider },
            walletlink: {
              url: config.defaultProvider,
              appName: 'bul.finance',
              appLogoUrl: 'https://raw.githubusercontent.com/bombmoney/bomb-assets/master/bomb-512.png',
            },
          }}
        >
          <Provider store={store}>
            <Updaters />
            <RefreshContextProvider>
              <LoonFinanceProvider>
                <ModalsProvider>
                  <BanksProvider>
                    <>
                      <Popups />
                      {children}
                    </>
                  </BanksProvider>
                </ModalsProvider>
              </LoonFinanceProvider>
            </RefreshContextProvider>
          </Provider>
        </UseWalletProvider>
      </TP>
    </TP1>
  );
};

export default App;
