import React from 'react';

const AnonymousLayout = ({ children }) => {
    return (
        <div className='page-auth-v3 layout-full'>
            <div className='page vertical-align text-center'>
                <div className='page-content vertical-align-middle animation-slide-top animation-duration-1'>
                    <div className='panel'>
                        <div className='panel-body'>
                            <img class="brand-img" src="/airbrick2_0.png" alt="..." />
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AnonymousLayout;