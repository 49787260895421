
import React, { Fragment, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import useWallet from 'use-wallet';
import { validate } from 'validate.js';
import FieldsGenerator from '../../components/FieldsGenerator';
import fillInObject from '../../_helpers/fillInObject';
import generateValidation from '../../_helpers/generateValidation';
import { authenticationService } from '../../_services/authentication.service';
import NFTMainImg from '../../assets/img/nftmain-buy.png';
import config from '../../_config';

const BuyFromCard = () => {
    const { account } = useWallet();

    const [user, setUser] = useState(null);
    const [bluePrint, setBluePrint] = useState({
        'address': {
            label: "APES Address",
            placeholder: "Enter APES Address",
            dataType: "String",
            default: "",
            required: true
        },
        'amount': {
            label: "Amount ($)",
            placeholder: "Enter Amount ($)",
            dataType: "String",
            default: "",
            required: true
        }
    });
    const [formObj, setFormObj] = useState(null);
    const [validationRule, setValidationRule] = useState(null);
    const [errors, setErrors] = useState({});
    const [isLoading, setLoading] = useState(true);
    const [stripeData, setStripeData] = useState(null);


    useEffect(() => {
        if (account && formObj && formObj.address === "") {
            handleChageCustom('address', account);
        }
    }, [account, formObj]);

    useEffect(() => {

        let { mFormObj, mValidationRule } = generateValidation(bluePrint);
        setFormObj(mFormObj); setValidationRule(mValidationRule); setLoading(false);

        setUser(authenticationService.getUserLocal());
    }, []);



    useEffect(() => {
        if (stripeData && stripeData.user) {
            document.getElementById("stripe-checkout-button").click();
        }
    }, [stripeData]);



    const handleChange = ({ target }) => {
        const { name, value } = target;
        handleChageCustom(name, value);
    }
    const handleChageCustom = (name, value) => {
        const errors = validate({ [name]: value }, { [name]: validationRule[name] });
        let obj = fillInObject(Object.assign({}, formObj), name, value);
        setFormObj(obj);
        setErrors(Object.assign({}, errors,
            errors ? errors : { [name]: undefined }
        ));
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const errors = validate(formObj, validationRule);
        if (errors) {
            toast.error("Please enter all required fields")
            setErrors(errors);
            return;
        }

        delete formObj["_id"];

        setStripeData({
            user: user._id,
            amount: formObj.amount,
            address: formObj.address,
            flag: new Date().getTime(),
            mode: "Card"
        })

        // setCoinPaymentsData({
        //     description: `${currencyDisplayHelper(infosetting, formObj.amount)} amount of BRICKS from ${user.name}`,
        //     custom: `user=${user._id}&amount=${formObj.amount}&address=${formObj.address}&flag=${new Date().getTime()}&mode=Crypto`
        // })

    }
    return (
        <div>
            <div className='flex-center mt-4'>
                <img src={NFTMainImg} alt="NFT Main logo" class="nft-image-buy" />

                <Fragment>
                    <form onSubmit={handleSubmit} className="profile-form buy-form">
                        <h3 className='profile-form-title'>Buy APES from Card</h3>
                        {formObj && <FieldsGenerator
                            fieldClass=""
                            errors={errors}
                            formObj={formObj}
                            handleChageCustom={handleChageCustom}
                            handleChange={handleChange}
                            loggingIn={false}
                            isLoading={isLoading}
                            bluePrint={bluePrint}

                        />
                        }


                        <button type="submit" className="btn btn-primary btn-block btn-lg mt-30" disabled={isLoading}>
                            {isLoading ? "Please Wait..." : "Buy from Card"}
                        </button>
                    </form>

                    {stripeData && <form action={`${config.baseUrl}/api/user/stripe/create-checkout-session`} method="POST"
                        className='hide'
                    >
                        <input type="text" name="user" value={stripeData.user} />
                        <input type="text" name="amount" value={stripeData.amount} />
                        <input type="text" name="address" value={stripeData.address} />
                        <input type="text" name="flag" value={stripeData.flag} />
                        <input type="text" name="mode" value={stripeData.mode} />
                        <button type="submit" id="stripe-checkout-button">Checkout</button>
                    </form>}
                </Fragment>
            </div>
        </div>
    );
};

export default BuyFromCard;