import { CardContent, Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { validate } from 'validate.js';
import config from '../../_config';
import currencyDisplayHelper from '../../_helpers/currencyDisplayHelper';
import fillInObject from '../../_helpers/fillInObject';
import generateValidation from '../../_helpers/generateValidation';
import socketUpdater from '../../_helpers/socketUpdater';
import { apiService } from '../../_services/api.service';
import { authenticationService } from '../../_services/authentication.service';
import './style.css';

import Upline from '../../assets/img/upline.png';
import Downline from '../../assets/img/downline.png';
import commission from '../../assets/img/commission.png';
import referralMSG from '../../assets/img/referral-msg.png';
import dottedCurve from './../../assets/img/referral-dotted-bar.png';
import refferalReg from './../../assets/img/referral-reg.png';
import refferalCom from './../../assets/img/referral-com.png';
import { Link } from 'react-router-dom';

const Refferal = ({ pageId, history }) => {
    const [email, setEmail] = useState("");
    const [balances, setBalances] = useState(null);
    const [bluePrint, setBluePrint] = useState({
        from: {
            label: "From",
            dataType: "Date",
        },
        to: {
            label: "To",
            dataType: "Date",
        },
    });
    const [formObj, setFormObj] = useState(null);
    const [validationRule, setValidationRule] = useState(null);
    const [errors, setErrors] = useState({});
    const [formError, setFormError] = useState(null);
    const [isLoading, setLoading] = useState(true);

    const [mainBalance, setMainBalance] = useState(null);
    const [user, setUser] = useState(null);

    useEffect(() => {
        setUser(authenticationService.getUserLocal());
    }, []);


    useEffect(() => {
        if (bluePrint) {
            let { mFormObj, mValidationRule } = generateValidation(bluePrint);

            try {
                let _params = JSON.parse(atob(pageId));
                if (!_params.formObj) {
                    setFormObj(mFormObj);
                }
            } catch (err) {
                setFormObj(mFormObj);
            }
            setValidationRule(mValidationRule); setLoading(false);
        }

    }, [bluePrint]);

    useEffect(() => {
        let tempSocket = null;
        if (user) {
            getRefferals();
            tempSocket = socketUpdater('transaction', null, null, (action, data) => {
                if ((action === "new" || action === "update") && data && data.user && data.user === user._id) {
                    getRefferals();
                }
            });
        }

        return () => {
            if (tempSocket) {
                tempSocket.socket.close();
            }
        }
    }, [user, pageId]);

    const getRefferals = () => {

        apiService.getAll(`transactions/f/balance`)
            .then(g => {
                setMainBalance(g.balance);
            })

        setBalances(null);
        let filter = {};
        // let page = 1;

        // try {
        //     let _params = JSON.parse(atob(pageId));
        //     if (_params.formObj) {
        //         filter = { query: _params.formObj };
        //         filter.query.from = new Date(filter.query.from);
        //         filter.query.to = new Date(filter.query.to);
        //         setFormObj(filter.query);
        //     }
        //     if (_params.page) {
        //         page = _params.page
        //     }
        // } catch (err) { }

        // filter["pageOptions"] = { page }
        apiService.add("transactions/r/balances", filter)
            .then(setBalances);

    }

    useEffect(() => {
        console.log({ balances });
    }, [balances]);

    const handleChange = ({ target }) => {
        const { name, value } = target;
        handleChageCustom(name, value);
    }

    const handleChageCustom = (name, value) => {
        console.log({ name, value });
        let errors = validate({ [name]: value }, { [name]: validationRule[name] });
        let obj = fillInObject(Object.assign({}, formObj), name, value, true);
        setFormObj(obj);
        setErrors(Object.assign({}, errors,
            errors ? errors : { [name]: undefined }
        ));
    }


    const fillURLAddressWithPage = () => {
        let data = { formObj };
        return btoa(JSON.stringify(data));
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const errors = validate(formObj, validationRule);
        if (errors) {
            toast.error("Please enter all required fields")
            setFormError(null);
            setErrors(errors);
            return;
        }

        delete formObj["_id"];
        history.push(`/referrals/${fillURLAddressWithPage()}`);

        // let newFormObj = btoa(JSON.stringify(formObj));
    }


    const handleSendInvite = () => {
        if (email === "") {
            return toast.error("Email Required");
        }

        apiService.add('auth/invite-email', { email, link: config.websiteURL })
            .then(g => {
                toast.success(g.message);
                setEmail("");
            })
            .catch(e => {
                toast.error("An error occured while sending email!");
            })

    }
    return user ? <div>
        <Grid container spacing={3}>
            {/* <Grid item xs={12} sm={3}>
                <CardContent align='center' className='card-content'>
                    <div className='dash-details'>
                        <img src={Upline} alt="Upline Logo" width="37px" height="21px" />
                        <div className='price-details'>
                            <span style={{ fontSize: '24px', alignContent: 'flex-start' }}>
                                {user.upline === "" ? "None" : user.upline}
                            </span>
                            <text style={{ fontSize: '16px', color: '#DDDFEE' }}>Upline</text>
                        </div>
                    </div>
                </CardContent>
            </Grid> */}
            <Grid item xs={12} sm={4}>
                <CardContent align='center' className='card-content'>
                    <div className='dash-details'>
                        <img src={Downline} alt="Upline Logo" width="37px" height="21px" />
                        <div className='price-details'>
                            <span style={{ fontSize: '24px', alignContent: 'flex-start' }}>
                                {/* {user.downLineLeft && user.downLineLeft !== "" ? `${user.downLineLeft}, ${user.downLineRight ? user.downLineRight : 'none'}` : 'None'} */}

                                {balances ? balances.referrals.length : "..."}
                            </span>
                            <text style={{ fontSize: '16px', color: '#DDDFEE' }}>Referee</text>
                        </div>
                    </div>
                </CardContent>
            </Grid>

            <Grid item xs={12} sm={4}>
                <CardContent align='center' className='card-content'>
                    <div className='dash-details'>
                        <img src={commission} alt="Upline Logo" width="37px" height="21px" />
                        <div className='price-details'>
                            <span style={{ fontSize: '24px', alignContent: 'flex-start' }}>
                                {balances ? currencyDisplayHelper(balances.commision) : '...'}
                            </span>
                            <text style={{ fontSize: '16px', color: '#DDDFEE' }}>Amount Earned</text>

                        </div>
                    </div>
                </CardContent>
            </Grid>

            <Grid item xs={12} sm={4}>
                <CardContent align='center' className='card-content'>
                    <div className='dash-details'>
                        <img src={commission} alt="Upline Logo" width="37px" height="21px" />
                        <div className='price-details'>
                            <span style={{ fontSize: '24px', alignContent: 'flex-start' }}>
                                {mainBalance ? currencyDisplayHelper(mainBalance) : '...'}
                                {(mainBalance && mainBalance > 0) ? <Link to="/withdraw" className='r-withdraw-button'>(Withdraw)</Link> : null}

                            </span>
                            <text style={{ fontSize: '16px', color: '#DDDFEE' }}>Balance</text>

                        </div>
                    </div>
                </CardContent>
            </Grid>
        </Grid>

        <div className='r-content'>
            <span className='r-heading'>Referrals</span>
            <span className='r-caption'>Invite you friends to Airbrick apes. If they signup, you and your friend will get $10 for free!</span>

            <div className='r-banner-container'>
                <div className='r-banner-item'>
                    <img src={referralMSG} className="r-banner-image" alt="Message" />
                    <span className='r-banner-item-title'>Send Invitation</span>
                    <span className='r-banner-item-caption '>
                        Send your referral link to friends and tell them how cool Airbrick apes is!
                    </span>
                </div>
                <div className='r-banner-item  r-banner-item-curve'>
                    <img src={dottedCurve} alt="Dotted Curve" className='r-dotted-curve' />
                </div>
                <div className='r-banner-item'>
                    <img src={refferalReg} className="r-banner-image" alt="Message" />
                    <span className='r-banner-item-title'>Registration</span>
                    <span className='r-banner-item-caption '>
                        Let them register to our services using your referral link.
                    </span>
                </div>
                <div className='r-banner-item r-banner-item-curve'>
                    <img src={dottedCurve} alt="Dotted Curve" className='r-dotted-curve' />
                </div>
                <div className='r-banner-item'>
                    <img src={refferalCom} className="r-banner-image" alt="Message" />
                    <span className='r-banner-item-title'>Get $10 for free!</span>
                    <span className='r-banner-item-caption '>
                        After completing Signup, You and your friends get $10 for free!
                    </span>
                </div>
            </div>
        </div>

        <div className='r-content'>
            <span className='r-heading'>Invite your Friends</span>
            <span className='r-caption'>Invite you friends addresses and send them invitations to join Airbrick apes.</span>

            <div className='r-invite'>
                <input placeholder='Email Address...' type="email" onChange={({ target }) => setEmail(target.value)} onKeyUp={({ key }) => { if (key === "Enter") handleSendInvite() }} />
                <div className='r-send-button flex-center' onClick={handleSendInvite}>
                    <i className='fa fa-send'></i>
                </div>
            </div>
        </div>


        <div className='r-content'>
            <span className='r-heading'>Share the referral link</span>
            <span className='r-caption'>You can also share your referral link by copying and sending it or sharing it on your social media.</span>
            <div className='r-link-container'>
                <div className='r-invite'>
                    <input placeholder='Referral Link' value={encodeURI(`${config.websiteURL}/signup/${user.name.toLowerCase()}`)} className="r-link-input" />
                    <span className='r-link-copy' onClick={async () => {
                        await navigator.clipboard.writeText(encodeURI(`${config.websiteURL}/signup/${user.name.toLowerCase()}`));
                        toast.success("Referral URL copied to clipboard!");
                    }}>Copy Link</span>
                </div>
                <div className='r-social-container'>
                    <a href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURI(config.websiteURL + "/signup/" + user.name.toLowerCase())}`}
                        target="_blank" without rel="noopener noreferrer">
                        <i className='fa fa-facebook'></i>
                    </a>
                    <a href={`https://twitter.com/intent/tweet?text=${encodeURI(config.websiteURL + "/signup/" + user.name.toLowerCase())}`}
                        target="_blank" without rel="noopener noreferrer">
                        <i className='fa fa-twitter'></i>
                    </a>
                    <a href={`https://www.linkedin.com/shareArticle?mini=true&url=${encodeURI(config.websiteURL + "/signup/" + user.name.toLowerCase())}&title=Connect%20with%20Airbrick%20APES`}
                        target="_blank" without rel="noopener noreferrer">
                        <i className='fa fa-linkedin'></i>
                    </a>
                </div>
            </div>
        </div>

        {balances ? <div className='r-content'>
            <span className='r-heading'>Referral Users</span>
            <div className='r-table'>
                <div className='r-table-header'>
                    <div className='r-table-item-1'>
                        <span className='r-table-heading'>User</span>
                    </div>
                    <div className='r-table-item-2'>
                        <span className='r-table-heading'>Referral Link</span>
                    </div>
                    <div className='r-table-item-3'>
                        <span className='r-table-heading'>Date</span>
                    </div>
                </div>

                {
                    balances.referrals.length > 0 ? balances.referrals.map((ref, key) => <div className='r-table-row'>
                        <div className='r-table-item-1'>
                            <span className='r-table-cell'>
                                <img src={`https://ui-avatars.com/api/?name=${ref.name}&background=25A4CF&color=fff`} alt={ref.name} className="" />
                                {ref.name}
                            </span>
                        </div>
                        <div className='r-table-item-2'>
                            <span className='r-table-cell'>{`${config.websiteURL}/signup/${ref.name.toLowerCase()}`}</span>
                        </div>
                        <div className='r-table-item-3'>
                            <span className='r-table-cell'>{new Date(ref.registeredOn).toLocaleDateString()}</span>
                        </div>
                    </div>)
                        : <div className='flex-center mt-4'>
                            No Downline Found!
                        </div>}
            </div>
        </div> : null}
    </div> :
        null;
    // return user ? (
    //     <div className='d-ad-container'>
    //         <div className='d-ad-left'>
    //             <div className='content-background mb-4'>
    //                 <h3 className='profile-form-title'>Refferals</h3>
    //                 <div className='d-r-status-container'>
    //                     <div className='d-r-status-item'>
    //                         <span className='d-r-status-heading'>
    //                             Upline
    //                         </span>
    //                         <span className='d-r-status-value'>
    //                             {user.upline === "" ? "None" : user.upline}
    //                         </span>
    //                     </div>
    //                     <div className='d-r-status-item'>
    //                         <span className='d-r-status-heading'>
    //                             Downline
    //                         </span>
    //                         <span className='d-r-status-value'>
    //                             {user.downLineLeft && user.downLineLeft !== "" ? `${user.downLineLeft} , ${user.downLineRight ? user.downLineRight : 'None'}` : 'None'}
    //                         </span>
    //                     </div>
    //                     <div className='d-r-status-item'>
    //                         <span className='d-r-status-heading'>
    //                             Active Referrals
    //                         </span>
    //                         <span className='d-r-status-value'>
    //                             {balances ? balances.totalReferrals : '...'}
    //                         </span>
    //                     </div>
    //                     <div className='d-r-status-item d-r-status-borderless'>
    //                         <span className='d-r-status-heading'>
    //                             Total Commision
    //                         </span>
    //                         <span className='d-r-status-value'>
    //                             {balances ? currencyDisplayHelper(balances.commision) : '...'}
    //                         </span>
    //                     </div>
    //                 </div>
    //             </div>

    //             <div className='content-background mt-3'>
    //                 <h3 className='profile-form-title'>Filter</h3>
    //                 {formObj && <form className="login-signup-form" onSubmit={handleSubmit}>
    //                     <FieldsGenerator
    //                         errors={errors}
    //                         formObj={formObj}
    //                         handleChageCustom={handleChageCustom}
    //                         handleChange={handleChange}
    //                         loggingIn={false}
    //                         isLoading={isLoading}
    //                         bluePrint={bluePrint}
    //                         key={0}
    //                     />
    //                     <button type="submit" className="btn btn-block primary-solid-btn border-radius mt-4 mb-3">
    //                         Apply Filter
    //                     </button>
    //                 </form>}

    //             </div>


    //         </div>
    //         <div className='d-ad-right'>
    //             <div className='content-background mt-3'>
    //                 <h3 className='profile-form-title'>Downlines</h3>
    //                 {balances ? <Fragment>
    //                     <TableContainer component={Paper} className="mt-2">
    //                         <Table sx={{ minWidth: 650 }} aria-label="simple table" className="bg-market-trends mt-4">
    //                             <TableHead>
    //                                 <TableRow>
    //                                     <TableCell>Username</TableCell>
    //                                     <TableCell>Email</TableCell>
    //                                     <TableCell>Date</TableCell>
    //                                 </TableRow>
    //                             </TableHead>
    //                             <TableBody>
    //                                 {
    //                                     balances.referrals.length > 0 ?
    //                                         balances.referrals.map((ref, key) => <TableRow key={key}>
    //                                             <TableCell>{ref.name}</TableCell>
    //                                             <TableCell>{ref.email}</TableCell>
    //                                             <TableCell>{new Date(ref.registeredOn).toLocaleDateString()}</TableCell>
    //                                         </TableRow>)
    //                                         :
    //                                         <TableRow>
    //                                             <TableCell colSpan={12}>
    //                                                 No Downline Found!
    //                                             </TableCell>
    //                                         </TableRow>
    //                                 }
    //                             </TableBody>
    //                         </Table>
    //                     </TableContainer>
    //                 </Fragment> :
    //                     null
    //                 }
    //             </div>
    //         </div>
    //     </div>
    // ) : null;
};

export default Refferal;