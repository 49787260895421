import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Grid, Typography, Link } from '@material-ui/core';

import { ReactComponent as IconTelegram } from '../../assets/img/telegram.svg';
import { ReactComponent as IconTwitter } from '../../assets/img/twitter.svg';
import { ReactComponent as IconGithub } from '../../assets/img/github.svg';
import { ReactComponent as IconDiscord } from '../../assets/img/discord.svg';

import Page from './../Page';

const useStyles = makeStyles((theme) => ({
  footer: {
    position: 'absolute',
    bottom: '0',
    paddingTop: '15px',
    paddingBottom: '15px',
    width: '100%',
    color: 'white',
    backgroundColor: '#030303',
    textAlign: 'center',
    height: '1.3rem',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  link: {
    width: '24px',
    height: '24px',
    display: 'inline',
    marginLeft: '20px',
  },

  img: {
    width: '24px',
    height: '24px',
  },
}));

const Footer = () => {
  const classes = useStyles();
  return (
    <footer className={classes.footer}>
      <Container maxWidth="lg">
        <Grid container>
          <Grid item xs={6}>
            <span className='MuiTypography-root copyright-footer MuiTypography-body2 MuiTypography-colorTextPrimary MuiTypography-alignLeft'>
              {'Copyright © '}
              <Link color="inherit" href="/">
                Airbrick APES
              </Link>{' '}
              {new Date().getFullYear()}
            </span>


            <a href='https://docs.airbrickapes.com' target="_blank" rel="noopener noreferrer" className='docs-link MuiTypography-root MuiTypography-body1'>
              DOCS
            </a>

          </Grid>

          <Grid item xs={6} style={{ textAlign: 'right', height: '20px' }}>
            <a
              href="https://twitter.com/LoonFinance"
              rel="noopener noreferrer"
              target="_blank"
              className={classes.link}
            >
              <IconTwitter style={{ fill: '#dddfee' }} />
            </a>
            {/* <a href="https://github.com/bulFinance" rel="noopener noreferrer" target="_blank" className={classes.link}>
              <IconGithub style={{fill: '#dddfee', height: '20px'}} />
            </a> */}
            <a href="https://t.me/+7-pcOVyPLjE2YTQ0" rel="noopener noreferrer" target="_blank" className={classes.link}>
              <IconTelegram style={{ fill: '#dddfee', height: '20px' }} />
            </a>
            <a href="https://discord.gg/dpKVPEe2" rel="noopener noreferrer" target="_blank" className={classes.link}>
              <IconDiscord style={{ fill: '#dddfee', height: '20px' }} />
            </a>
          </Grid>
        </Grid>
      </Container>
    </footer >
  );

};

const FooterNew = () => {
  return (
    <Page>
      <div className='container'>
        <div className='row footer-row'>
          <div className='col-sm-6'>
            <h5>About</h5>
          </div>
          <div className='col-sm-6'></div>
          <div className='col-sm-6'></div>
        </div>
      </div>
    </Page>

  )
}

export default Footer;
