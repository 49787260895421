import {ChainId} from '@pancakeswap/sdk';
import {Configuration} from './bomb-finance/config';
import {BankInfo} from './bomb-finance';
import img1 from  './assets/img/img1.png';
import img2 from  './assets/img/img2.png';
import img3 from  './assets/img/img3.png';
import img4 from  './assets/img/img4.png';

const configurations: {[env: string]: Configuration} = {  
  development: {
    chainId: 56,
    networkName: 'BSC Mainnet',
    ftmscanUrl: 'https://bscscan.com',
    defaultProvider: 'https://bsc-dataseed.binance.org/',
    deployments: require('./bomb-finance/deployments/deployments.mainnet.json'),
    externalTokens: {
      WBNB: ['0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c', 18],
      FUSDT: ['0x55d398326f99059fF775485246999027B3197955', 18], // This is actually BUSD on mainnet not fusdt
      BUSD: ['0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56', 18],
      FTM: ['0xad29abb318791d579433d831ed122afeaf29dcfe', 18],
      ETH: ['0x2170ed0880ac9a755fd29b2688956bd959f933f8',18],
      USDC: ['0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d', 18],
      BTCB: ['0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c', 18],
      DOGE: ['0xba2ae424d960c26247dd6c32edc70b295c744c43',8],
      SHIB: ['0x2859e4544C4bB03966803b044A93563Bd2D0DD4D',18],
      'USDT-BNB-LP': ['0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16', 18],
      'LOON-BNB-LP': ['0x8A53a360BCD6217095548Ccad98AE0625F87FC4c', 18],
      'USDT-BUSD-LP': ['0x7EFaEf62fDdCCa950418312c6C91Aef321375A00', 18],
      'LOON-BUSD-LP': ['0xf6CA820E94cf6A121b13BAC88EC7dE1c9Df30331', 18],
      'AKASHARE-BNB-LP': ['0x10B3a982Bd35A23e3996811E246122aD76a39785', 18],
      // 'LOON-BTCB-APELP': ['0xB6E85031F313563bF12ea414118978C8BD78db5D', 18],
    },
    baseLaunchDate: new Date('2021-11-20 1:00:00Z'),
    bondLaunchesAt: new Date('2020-12-03T15:00:00Z'),
    boardroomLaunchesAt: new Date('2021-11-20T00:00:00Z'),
    refreshInterval: 10000,
  },
  production: {
    chainId: 56,
    networkName: 'BSC Mainnet',
    ftmscanUrl: 'https://bscscan.com',
    defaultProvider: 'https://bsc-dataseed.binance.org/',
    deployments: require('./bomb-finance/deployments/deployments.mainnet.json'),
    externalTokens: {
      WBNB: ['0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c', 18],
      FUSDT: ['0x55d398326f99059fF775485246999027B3197955', 18], // This is actually BUSD on mainnet not fusdt
      BTCB: ['0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c', 18],
      BUSD: ['0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56', 18],
      FTM: ['0xad29abb318791d579433d831ed122afeaf29dcfe', 18],
      ETH: ['0x2170ed0880ac9a755fd29b2688956bd959f933f8',18],
      USDC: ['0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d', 18],
      DOGE: ['0xba2ae424d960c26247dd6c32edc70b295c744c43',8],
      SHIB: ['0x2859e4544C4bB03966803b044A93563Bd2D0DD4D',18],
      'USDT-BNB-LP': ['0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16', 18],
      'USDT-BUSD-LP': ['0x7EFaEf62fDdCCa950418312c6C91Aef321375A00', 18],
      'LOON-BUSD-LP': ['0xf6CA820E94cf6A121b13BAC88EC7dE1c9Df30331', 18],
      'LOON-BNB-LP': ['0x8A53a360BCD6217095548Ccad98AE0625F87FC4c', 18],
      'AKASHARE-BNB-LP': ['0x10B3a982Bd35A23e3996811E246122aD76a39785', 18],
      // 'AKASHARE-BNB-APELP': ['0x0dE2a71b2f43CF588A00422d41E1C02D0E08D552', 18],
      // 'LOON-BTCB-APELP': ['0xB6E85031F313563bF12ea414118978C8BD78db5D', 18],
    },
    baseLaunchDate: new Date('2021-11-20 1:00:00Z'),
    bondLaunchesAt: new Date('2020-12-03T15:00:00Z'),
    boardroomLaunchesAt: new Date('2021-11-20T00:00:00Z'),
    refreshInterval: 10000,
  },
};

export const bankDefinitions: {[contractName: string]: BankInfo} = {
  /*
  Explanation:
  name: description of the card
  poolId: the poolId assigned in the contract
  sectionInUI: way to distinguish in which of the 3 pool groups it should be listed
        - 0 = Single asset stake pools
        - 1 = LP asset staking rewarding LOON
        - 2 = LP asset staking rewarding AKASHARE
  contract: the contract name which will be loaded from the deployment.environmnet.json
  depositTokenName : the name of the token to be deposited
  earnTokenName: the rewarded token
  finished: will disable the pool on the UI if set to true
  sort: the order of the pool
  */
  // LoonBTCApeLPLoonRewardPool: {
  //   name: 'Earn LOON by LOON-BTCB Ape LP',
  //   poolId: 2,
  //   sectionInUI: 1,
  //   contract: 'LoonBTCApeLPLoonRewardPool',
  //   depositTokenName: 'LOON-BTCB-APELP',
  //   earnTokenName: 'LOON',
  //   finished: true,
  //   sort: 2,
  //   closedForStaking: true,
  // },
  // LoonBTCLPLoonRewardPool: {
  //   name: 'Earn LOON by LOON-BTC LP',
  //   poolId: 0,
  //   sectionInUI: 1,
  //   contract: 'LoonBTCLPLoonRewardPool',
  //   depositTokenName: 'LOON-BTCB-LP',
  //   earnTokenName: 'LOON',
  //   finished: true,
  //   sort: 7,
  //   closedForStaking: true,
  // },
  // LoonShibaGenesisRewardPool: {
  //   name: 'Earn LOON by SHIB',
  //   poolId: 4,
  //   sectionInUI: 0,
  //   contract: 'LoonShibaGenesisRewardPool',
  //   depositTokenName: 'SHIB',
  //   earnTokenName: 'LOON',
  //   finished: false,
  //   sort: 4,
  //   closedForStaking: true,
  // },
  // LoonDogeGenesisRewardPool: {
  //   name: 'Earn LOON by DOGE',
  //   poolId: 5,
  //   sectionInUI: 0,
  //   contract: 'LoonDogeGenesisRewardPool',
  //   depositTokenName: 'DOGE',
  //   earnTokenName: 'LOON',
  //   finished: false,
  //   sort: 5,
  //   closedForStaking: true,
  // },
  // LoonBTCGenesisRewardPool: {
  //   name: 'Earn LOON by BTCB',
  //   poolId: 3,
  //   sectionInUI: 0,
  //   contract: 'LoonBTCGenesisRewardPool',
  //   depositTokenName: 'BTCB',
  //   earnTokenName: 'LOON',
  //   finished: false,
  //   sort: 3,
  //   closedForStaking: true,
  // },
  // LoonUSDCGenesisRewardPool: {
  //   name: 'Earn LOON by USDC',
  //   poolId: 2,
  //   sectionInUI: 0,
  //   contract: 'LoonUSDCGenesisRewardPool',
  //   depositTokenName: 'USDC',
  //   earnTokenName: 'LOON',
  //   finished: false,
  //   sort: 2,
  //   closedForStaking: true,
  // },

  // LoonWBNBGenesisRewardPool: {
  //   name: 'Earn LOON by WBNB',
  //   poolId: 0,
  //   sectionInUI: 0,
  //   contract: 'LoonWBNBGenesisRewardPool',
  //   depositTokenName: 'WBNB',
  //   earnTokenName: 'LOON',
  //   finished: false,
  //   sort: 0,
  //   closedForStaking: true,
  // },
  // LoonBUSDGenesisRewardPool: {
  //   name: 'Earn LOON by BUSD',
  //   poolId: 1,
  //   sectionInUI: 0,
  //   contract: 'LoonBUSDGenesisRewardPool',
  //   depositTokenName: 'BUSD',
  //   earnTokenName: 'LOON',
  //   finished: false,
  //   sort: 1,
  //   closedForStaking: true,
  // },
  // LoonWFTMGenesisRewardPool: {
  //   name: 'Earn LOON by FTM',
  //   poolId: 6,
  //   sectionInUI: 0,
  //   contract: 'LoonWFTMGenesisRewardPool',
  //   depositTokenName: 'FTM',
  //   earnTokenName: 'LOON',
  //   finished: false,
  //   sort: 6,
  //   closedForStaking: true,
  // },

  LoonBusdLPLoonRewardPool: {
    name: 'Earn LOON by LOON-BUSD LP',
    poolId: 0,
    sectionInUI: 1,
    contract: 'LoonBusdLPLoonRewardPool',
    depositTokenName: 'LOON-BUSD-LP',
    earnTokenName: 'LOON',
    finished: false,
    sort: 8,
    closedForStaking: false,
  },
  // LoonShibaRewardPool: {
  //   name: 'Earn LOON by SHIBA',
  //   poolId: 2,
  //   sectionInUI: 0,
  //   contract: 'LoonShibaGenesisRewardPool',
  //   depositTokenName: 'SHIBA',
  //   earnTokenName: 'LOON',
  //   finished: false,
  //   sort: 3,
  //   closedForStaking: true,
  // },
  // LoonZooRewardPool: {
  //   name: 'Earn LOON by ZOO',
  //   poolId: 3,
  //   sectionInUI: 0,
  //   contract: 'LoonZooGenesisRewardPool',
  //   depositTokenName: 'ZOO',
  //   earnTokenName: 'LOON',
  //   finished: false,
  //   sort: 4,
  //   closedForStaking: true,
  // },

  // LoonFtmLPLoonRewardPoolOld: {
  //   name: 'Earn LOON by LOON-BNB LP',
  //   poolId: 0,
  //   sectionInUI: 1,
  //   contract: 'LoonFtmLpLoonRewardPoolOld',
  //   depositTokenName: 'LOON-BNB-LP',
  //   earnTokenName: 'LOON',
  //   finished: true,
  //   sort: 9,
  //   closedForStaking: true,
  // },
  // LoonFtmLPBULShareRewardPool: {
  //   name: 'Earn AKASHARE by LOON-BNB LP',
  //   poolId: 0,
  //   sectionInUI: 2,
  //   contract: 'LoonFtmLPBULShareRewardPool',
  //   depositTokenName: 'LOON-BNB-LP',
  //   earnTokenName: 'AKASHARE',
  //   finished: false,
  //   sort: 6,
  //   closedForStaking: false,
  // },

  // LOONshareBnbLPApeBULShareRewardPool: {
  //   name: 'Earn AKASHARE by AKASHARE-BNB LP',
  //   poolId: 2,
  //   sectionInUI: 2,
  //   contract: 'LOONshareBnbLPApeBULShareRewardPool',
  //   depositTokenName: 'AKASHARE-BNB-LP',
  //   earnTokenName: 'AKASHARE',
  //   finished: false,
  //   sort: 7,
  //   closedForStaking: false,
  // },
  // LoonBtcbLPApeBULShareRewardPool: {
  //   name: 'Earn AKASHARE by LOON-BTCB LP',
  //   poolId: 3,
  //   sectionInUI: 2,
  //   contract: 'LoonBtcbLPApeBULShareRewardPool',
  //   depositTokenName: 'LOON-BTCB-LP',
  //   earnTokenName: 'AKASHARE',
  //   finished: false,
  //   sort: 7,
  //   closedForStaking: false,
  // },
  // LOONshareBnbApeLPBULShareRewardPool: {
  //   name: 'Earn AKASHARE by AKASHARE-BNB Ape LP',
  //   poolId: 2,
  //   sectionInUI: 2,
  //   contract: 'LOONshareBnbApeLPBULShareRewardPool',
  //   depositTokenName: 'AKASHARE-BNB-APELP',
  //   earnTokenName: 'AKASHARE',
  //   finished: true,
  //   sort: 5,
  //   closedForStaking: true,
  // },
  // LoonBtcbApeLPBULShareRewardPool: {
  //   name: 'Earn AKASHARE by LOON-BTCB Ape LP',
  //   poolId: 3,
  //   sectionInUI: 2,
  //   contract: 'LoonBtcbApeLPBULShareRewardPool',
  //   depositTokenName: 'LOON-BTCB-APELP',
  //   earnTokenName: 'AKASHARE',
  //   finished: true,
  //   sort: 4,
  //   closedForStaking: true,
  // },
  // LOONshareBnbLPBULShareRewardPool: {
  //   name: 'Earn AKASHARE by AKASHARE-BNB LP',
  //   poolId: 0,
  //   sectionInUI: 2,
  //   contract: 'LOONshareBnbLPBULShareRewardPool',
  //   depositTokenName: 'AKASHARE-BNB-LP',
  //   earnTokenName: 'AKASHARE',
  //   finished: false,
  //   sort: 2,
  //   closedForStaking: false,
  // },
  // LOONBnbLPBULShareRewardPool: {
  //   name: 'Earn AKASHARE by LOON-BNB LP',
  //   poolId: 1,
  //   sectionInUI: 2,
  //   contract: 'LOONBnbLPBULShareRewardPool',
  //   depositTokenName: 'LOON-BNB-LP',
  //   earnTokenName: 'AKASHARE',
  //   finished: false,
  //   sort: 2,
  //   closedForStaking: false,
  // },
  // LoonBtcbLPBULShareRewardPool: {
  //   name: 'Earn AKASHARE by LOON-BTCB LP',
  //   poolId: 1,
  //   sectionInUI: 2,
  //   contract: 'LoonBtcbLPBULShareRewardPool',
  //   depositTokenName: 'LOON-BTCB-LP',
  //   earnTokenName: 'AKASHARE',
  //   finished: false,
  //   sort: 1,
  //   closedForStaking: false,
  // },
};

export const announcedata = [
  {
    date:"7.24.2022",
    content:"The EMP team hosts AMA’s on YouTube at 10:45pm UTC 🎤Check out our latest AMA featuring our main host AJ",
    publish: "YouTube"
  },
  {
    date:"8.24.2022",
    content:"The EMP team hosts AMA’s on YouTube at 10:45pm UTC 🎤Check out our latest AMA featuring our main host AJ",
    publish: "YouTube"
  },
  {
    date:"9.09.2022",
    content:"📺 OFFICIALLY MONETIZED! Our YouTube Channel is now officially monetized and revenue from that will be feeding EMP. Revenue streams are key, no matter where they come from!",
    publish: "YouTbue"
  },
]

export const galleris = [
  {
    name: "KID APE",
    img: img1,
    price: "$9.35",
  },
  {
    name: "SPACE APE",
    img: img2,
    price: "$15.54",
  },
  {
    name: "RICH APE",
    img: img3,
    price: "$240.53",
  },
  {
    name: "STRONG APE",
    img: img4,
    price: "$17.98",
  }
]
export default configurations[process.env.NODE_ENV || 'development'];
