import React, { Fragment, useEffect, useState } from 'react';
import { Switch } from 'react-router-dom';
import { toast } from 'react-toastify';
import useWallet from 'use-wallet';
import { validate } from 'validate.js';
import FieldsGenerator from '../../components/FieldsGenerator';
import Page from '../../components/Page';
import Field from '../../_common/field';
import currencyDisplayHelper from '../../_helpers/currencyDisplayHelper';
import fillInObject from '../../_helpers/fillInObject';
import generateValidation from '../../_helpers/generateValidation';
import { apiService } from '../../_services/api.service';
import { authenticationService } from '../../_services/authentication.service';
import './style.css';

const Withdraw = ({ history }) => {
    const [balance, setBalance] = useState(null);
    // const [userKYC, setUserKYC] = useState(null);
    // const withDrawLimit = selectedCoin !== null && userKYC !== null ? (userKYC === 1 ? selectedCoin.minWithdrawAmountWithKYC : selectedCoin.minWithdrawAmountWithoutKYC) : 0
    const [setting, setSetting] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const [user, setUser] = useState(null);

    const { account } = useWallet();


    const [step, setStep] = useState("form");
    const [enabled, setEnabled] = useState(false);
    const [bluePrint, setBluePrint] = useState({
        'address': {
            label: "Address",
            placeholder: "Enter Address",
            dataType: "String",
            default: "",
            required: true
        },
        'amount': {
            label: "Amount ($)",
            placeholder: "Enter Amount ($)",
            dataType: "Number",
            default: "",
            required: true
        }

    });
    const [formObj, setFormObj] = useState(null);
    const [validationRule, setValidationRule] = useState(null);
    const [errors, setErrors] = useState({});
    const [formError, setFormError] = useState(null);


    useEffect(() => {
        setUser(authenticationService.getUserLocal());
        getBalance();
        apiService.getAll('settings/fetch/client')
            .then(setting => {
                if (setting.withdrawEnabled) {
                    setEnabled(true);
                    setSetting(setting);
                } else {
                    history.push(`/dashboard/home`)
                }
            });
    }, []);

    const getBalance = () => {
        setBalance(null);
        apiService.getAll(`transactions/f/balance`)
            .then(g => {
                setBalance(g.balance);
            })
    }




    useEffect(() => {
        if (account && enabled) {
            let { mFormObj, mValidationRule } = generateValidation(bluePrint);
            mFormObj.address = account;
            setFormObj(mFormObj);
            setValidationRule(mValidationRule);
        }
    }, [account, enabled]);


    useEffect(() => {
        if (formObj && setting) {
            setLoading(false);
        }
    }, [formObj, setting]);

    const handleChange = ({ target }) => {
        const { name, value } = target;
        handleChageCustom(name, value);
    }
    const limitValidator = (amount, errors) => {
        if (!errors["amount"]) {
            if (amount > balance) {
                errors["amount"] = ["Amount is greater then available balance!"];
            } else if (amount > setting.withdrawLimitMax) {
                errors["amount"] = [`Maxmimum withdraw limit is ${currencyDisplayHelper(setting.withdrawLimitMax)}`];
            } else if (amount < setting.withdrawLimitMin) {
                errors["amount"] = [`Minimum withdraw limit is ${currencyDisplayHelper(setting.withdrawLimitMin)}`];
            }
        }
        return errors;
    }
    const handleChageCustom = (name, value) => {
        let errors = validate({ [name]: value }, { [name]: validationRule[name] });
        let obj = fillInObject(Object.assign({}, formObj), name, value);

        console.log({ errors, validationRule });

        let amount = parseFloat(obj.amount);
        amount = isNaN(amount) ? 0 : amount;

        errors = errors ? errors : {};

        errors = limitValidator(amount, errors);

        setFormObj(obj);
        setErrors(Object.assign({}, errors,
            errors ? errors : { [name]: undefined }
        ));
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        let errors = validate(formObj, validationRule);
        if (errors) {
            toast.error("Please enter all required fields")
            setFormError(null);
            setErrors(errors);
            return;
        }

        let amount = parseFloat(formObj.amount);
        amount = isNaN(amount) ? 0 : amount;
        errors = errors ? errors : {};

        errors = limitValidator(amount, errors);
        if (errors && errors.amount && errors.amount.length > 0) {
            setErrors(errors);
            return;
        }

        delete formObj["_id"];
        setLoading(true);
        let promise = null
        promise = apiService.add('withdraw-requests', {
            ...formObj,
            user: user._id,
        }).then(g => {
            toast.success(`Your withdraw request has been submitted. It'll take 10 to 12 hours to proceed your request. Thank you!`);
            setTimeout(() => {
                window.location.reload();
            }, 3000);
        })


        if (promise) {
            promise.catch(e => {
                setLoading(false);
                let error = e.response ? e.response.data.message : e.message;
                toast.error(error);
            })
        }


    }



    return (
        <Switch>
            <Page>
                <div className='r-content'>
                    <span className='r-heading'>Withdraw</span>
                    <span className='r-caption'>Withdraw referral commission to your address</span>

                    {balance !== null ? <div className='w-container'>
                        <span className='r-caption mb-4'>Available Balance: <span className='primary-text'>${balance}</span></span>
                        {/* <Field fieldClass="" label="Available Balance">
                            <input
                                name="balance"
                                type="text"
                                value={balance}
                                disabled
                            />
                        </Field> */}

                        <form onSubmit={handleSubmit} className="mt-4">
                            {formObj &&
                                <FieldsGenerator
                                    errors={errors}
                                    formObj={formObj}
                                    handleChageCustom={handleChageCustom}
                                    handleChange={handleChange}
                                    loggingIn={false}
                                    isLoading={isLoading}
                                    bluePrint={bluePrint}
                                    fieldClass="w-field"
                                />
                            }
                            <button type="submit" className="btn btn-primary btn-block btn-lg mt-30" disabled={isLoading}>
                                {isLoading ? "Please Wait..." : "Submit"}
                            </button>
                        </form>
                    </div> : <div>
                        <span>Loading...</span>
                    </div>}
                </div>
            </Page>
        </Switch>
    );
};

export default Withdraw;